<template>
  <div>
    <h2 class="page-title-bar" v-if="$t(flagSelected)===$t('Updated Products')">
      <i class="ico ico-product"></i>{{ $t('Update Records') }}
    </h2>
    <h2 class="page-title-bar" v-if="$t(flagSelected)===$t('Scheduled Products')">
      <i class="ico ico-product"></i>{{ $t('Scheduled Products') }}
    </h2>
    <!-- 검색창 -->
    <div class="searchArea">
      <v-select
                v-model="flagSelected"
                :items="[$t('Updated Products'), $t('Scheduled Products')]"
                :label="$t('Product Update Type')"
                outlined
                dense
                @change="changeType"
                class="form-input"
            style="width:150px"
              ></v-select>
      <v-text-field
        outlined
        dense
        hide-details
        v-model="searchBy.fileName"
        @keyup.enter="searchRecords"
        :label="$t('File Name')"
        :placeholder="$t('Input file name')"
        class="form-input ml-3"
        clearable
      ></v-text-field>
      <v-menu
        v-model="showStartDatePicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="searchBy.startDate"
            @click:clear="clearDate(searchBy, 'startDate')"
            :label="`${$t('Period')}`"
            :placeholder="`${$t('Select start date')}`"
            class="form-input ml-3"
            v-bind="attrs"
            v-on="on"
            outlined
            dense
            hide-details
            readonly
            clearable
          >
            <template v-slot:append>
              <img width="16" height="16" src="@/assets/img/ico-calendar.png" />
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="searchBy.startDate"
          @change="checkStartDate"
          :locale="languageCodes[$store.getters['dataStore/GET_CURRENT_LANGUAGE']]"
          @input="showStartDatePicker = false"
          no-title
        ></v-date-picker>
      </v-menu>
      <span class="ml-1 mr-1">~</span>
      <v-menu
        v-model="showEndDatePicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="searchBy.endDate"
            @click:clear="clearDate(searchBy, 'endDate')"
            v-bind="attrs"
            v-on="on"
            :placeholder="`${$t('Select end date')}`"
            class="form-input"
            outlined
            dense
            hide-details
            solo
            readonly
            clearable
          >
            <template v-slot:append>
              <img width="16" height="16" src="@/assets/img/ico-calendar.png" />
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="searchBy.endDate"
          :locale="languageCodes[$store.getters['dataStore/GET_CURRENT_LANGUAGE']]"
          @change="checkEndDate"
          @input="showEndDatePicker = false"
          no-title
        ></v-date-picker>
      </v-menu>
      <v-btn text class="btn type-search ml-3"  @click="searchRecords">{{
        $t('Search')
      }}</v-btn>
    </div>
    <v-data-table v-if="$t(flagSelected) ===$t('Updated Products')"
      item-key="id"
      :headers="recordHeaders"
      :items="tableItems"
      :page.sync="page"
      :hide-default-footer="true"
      :options.sync="options"
      :server-items-length="totalRecords"
      class="tbl-type01 mt-10"

    >
      <template slot="no-data">
        <p>
          {{ $t('No data available') }}
        </p>
      </template>
      <template slot="item.download" slot-scope="props">
            <v-btn class="mx-2" icon @click="() => toDownloadarticles(props.item)">
                <v-icon dark>mdi-download</v-icon>
            </v-btn>
        </template>
    </v-data-table>
    <v-data-table v-if="$t(flagSelected) ===$t('Scheduled Products')"
      item-key="id"
      :headers="schedulerecordHeaders"
      :items="tableItems"
      :page.sync="page"
      :hide-default-footer="true"
      :options.sync="options"
      :server-items-length="totalRecords"
      class="tbl-type01 mt-10"
      :single-select="true"
      show-select
      style="cursor:pointer"
      @click:row="handleRowClick"
      v-model="selected"
    >
      <template slot="no-data">
        <p>
          {{ $t('No data available') }}
        </p>
      </template>
      <template slot="item.download" slot-scope="props">
            <v-btn class="mx-2" icon  @click.stop="toDownloadarticles(props.item)">
                <v-icon dark>mdi-download</v-icon>
            </v-btn>
        </template>
    </v-data-table>
    <!-- Button group -->
    <div class="table-options">
      <div>
        <v-btn
        v-if="$t(flagSelected) ===$t('Updated Products')"
          @click="exportRecords"
          :disabled="exportDisabled"
          class="btn"
          text
          >{{ $t('Export') }}</v-btn
        >
        <v-btn
        v-if="$t(flagSelected) !==$t('Updated Products')"
          @click="deleteUpdateRecords"
          :disabled="deleteBtnDisabled"
          class="btn ml-2"
          text
          >{{ $t('Delete') }}</v-btn
        >
      </div>

      <!-- Pagination -->
        <div class="pageInfo">{{ pageInfoText }}</div>
      <div>
        <v-pagination
          v-model="page"
          @input="paging"
          :length="totalPages * 1"
          :total-visible="totalVisiblePages"
          color="#2f3b4c"
          style="float:right"
        >
        </v-pagination>
      </div>
    </div>
    <a ref="link" :style="{ display: 'none' }" />

        <!---- dialog for edit -->
    <v-dialog
      v-model="editProcessedData"
      width="600"
    >
      <div style="padding: 40px 50px;background-color:#fff;position:relative">
        <h2 class="page-title-bar mb-5">
          <i class="ico ico-product"></i>{{ $t("Edit Scheduled Product") }}
        </h2>
        <div class="product_detail">
          <v-select
            v-model="timezone"
            :label="$t('Timezone')"
            :placeholder="$t('Select Time Zone')"
            :items="timezoneList"
            class="form-select mb-4"
            outlined
            dense
            hide-details
            :item-text="getFieldText"
            :item-value="'name'"
            @click:clear="clearTimeZone"
            @click="clearTimeZone"
          >
            <template v-slot:prepend-item>
              <v-text-field
                solo
                outlined
                hide-details
                dense
                placeholder="Search Timezone"
                ref="searchTerm"
                v-model="searchTerm"
                @input="searchTimezone"
                class="form-input search-v-select"
              ></v-text-field>
              <v-divider></v-divider>
            </template>
          </v-select>
          <v-menu
                v-model="schedule.showStartDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="250px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="scheduleTime"
                    @click:clear="scheduleTime = null"
                    :label="`${$t('Schedule Time *')}`"
                    :placeholder="`${$t('Select Schedule Time')}`"
                    class="form-select mb-4 col mandatory"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    readonly
                    clearable
                    prepend-icon=""
                    append-icon="mdi-calendar-month"
                    ref="calendarstart"
                    @click="setCurrentDate"
                    @click:append="
                      $refs.calendarstart.$el.querySelector('input').click()
                    "
                  >
                  </v-text-field>
                </template>
                <date-picker
                  mode="dateTime"
                  v-model="startTime"
                  :min-date="new Date()"
                  :minute-increment="30"
                  :is-required="true"
                  is24hr
                  :show-current="false"
                />
              </v-menu>
              <v-row>
                <v-col style="margin-top: -12px">
                  <p class="label-txt mb-2">
                    {{ $t(`Browse File ( Extension defined in`)}} <b> System Config > Product File Config</b> )

                    <span class="redbold">*</span>
                  </p>
                  <v-file-input
                    outlined
                    dense
                    hide-details
                    solo
                    flat
                    :placeholder="`${$t('Select File')}`"
                    class="form-file"
                    v-model="filesLater"
                    @change="appendFilesLater()"
                    prepend-icon=""
                    append-icon="mdi-file-upload"
                    ref="uploadFile2"
                    :clearable="false"
                    @click:append="
                      $refs.uploadFile1.$el.querySelector('input').click()
                    "
                  >
                  </v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p class="label-txt">
                    {{ $t("Selected Files") }} <span class="redbold"> * </span>
                  </p>
                  <ul class="selectedFile-list mt-2">
                    <template v-if="filesToUploadLater.length > 0">
                      <li
                        v-for="(file, i) in filesToUploadLater"
                        :key="file.name"
                      >
                        <p v-if="i === filesToUploadLater.length - 1">
                          {{ file.name }}
                        </p>
                        <v-btn
                          @click="removeFileLater(file.name)"
                          text
                          icon
                          v-if="i === filesToUploadLater.length - 1"
                          ><img
                            width="20"
                            height="20"
                            src="@/assets/img/ico-delete2.png"
                        /></v-btn>
                      </li>
                    </template>
                    <template v-if="filesToUploadLater.length === 0">
                      <li>
                        <p>No Files selected</p>
                      </li>
                    </template>
                  </ul>
                </v-col>
              </v-row>
        </div>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="handleSaveClick" class="btn" text icon>{{
            $t("Upload")
          }}</v-btn>
          <v-btn @click="editProcessedData = false" class="btn" text icon>{{
            $t("Cancel")
          }}</v-btn>
        </v-card-actions>
      </div>
    </v-dialog>

    <!-- End -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from '@/plugins/eventBus'
import { getRequestConfig, exportFiles } from '@/plugins/exporter'
import { setTempObj, getTempObj } from '@/plugins/sessionStorageManager'
import codes from '@/plugins/codes'
import commons from '@/plugins/commons'
import FileUploader from '@/mixins/FileUploader'
import { DatePicker } from 'v-calendar'
import moment from 'moment'

export default {
  name: 'UpdateRecords',
  components: {
    DatePicker
  },
  mixins: [FileUploader],
  data () {
    const startTime = new Date()
    startTime.setMinutes(0, 0, 0)
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      user: null,
      showStartDatePicker: false,
      showEndDatePicker: false,
      btnShowProductExport: false,
      page: 1,
      totalPages: 0,
      totalVisiblePages: 7,
      pageInfoText: '',
      options: {
        sortBy: ['created'],
        sortDesc: [true]
      },
      requestConfig: {},
      tableItems: [],
      totalRecords: null,
      searchBy: {
        fileName: null,
        startDate: null,
        endDate: null
      },
      flagSelected: this.$t('Updated Products'),
      selected: [],
      rowSelectedData: [],
      editProcessedData: false,
      timezone: '',
      timezoneList: [],
      timezoneCopy: [],
      searchTerm: '',
      schedule: {},
      scheduleTime: '',
      fileUploadFormat: '',
      filesToUploadLater: [],
      filesToUpload: [],
      fileUploadVisible: false,
      filesLater: [],
      startTime,
      requests: {
        uploadFileslater: {
          method: 'post',
          url: '/api/common/articles/schedule'
        }
      },
      allbtndisabled: false,
      languageCodes: {
        English: 'en',
        한국어: 'ko',
        Deutsch: 'de',
        Francais: 'fr',
        中文: 'zh',
        Espanol: 'es',
        Italiano: 'it',
        Portugal: 'por',
        日本語: 'jp'
      }
    }
  },
  computed: {
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    recordHeaders () {
      return [
        {
          text: '#',
          sortable: false,
          align: 'center',
          value: 'id',
          width: '7%'
        },
        { text: this.$t('FILE NAME'), value: 'name', width: '18%' },
        { text: this.$t('RESULT'), value: 'result', width: '10%' },
        { text: this.$t('TOTAL'), value: 'total', width: '10%' },
        { text: this.$t('SUCCESS'), value: 'success', width: '10%' },
        { text: this.$t('FAIL'), value: 'fail', width: '10%' },
        { text: this.$t('UPLOAD DATE'), value: 'created', width: '12%', sortable: true },
        { text: this.$t('COMPLETED DATE'), sortable: true, value: 'completed', width: '12%' },
        { text: this.$t('DOWNLOAD (Till 7 Days)'), sortable: false, value: 'download' }

      ]
    },
    schedulerecordHeaders () {
      return [
        { text: '#', sortable: false, align: 'center', value: 'id', width: '7%' },
        { text: this.$t('FILE NAME'), value: 'fileName', width: '15%', sortable: true },
        { text: this.$t('SCHEDULE TIME'), value: 'scheduleTime', width: '12%', sortable: true },
        { text: this.$t('LAST UPDATED'), sortable: true, value: 'lastModifiedDate', width: '12%' },
        { text: this.$t('PROCESSED DATE'), sortable: true, value: 'processDate', width: '12%' },
        { text: this.$t('STATUS'), value: 'fileStatus', width: '12%', sortable: true },
        { text: this.$t('EXECUTION RESULT'), value: 'executionResult', width: '12%', sortable: true },
        { text: this.$t('DOWNLOAD (Till 7 Days)'), sortable: false, value: 'download' }
      ]
    },
    deleteBtnDisabled () {
      return this.selected.length !== 1
    },
    exportDisabled () {
      let btnDisabled = this.btnShowProductExport
      if (!btnDisabled) {
        if (this.tableItems !== undefined) {
          btnDisabled = this.tableItems.length < 1
        } else {
          btnDisabled = true
        }
      }
      return btnDisabled
    }
  },
  watch: {
    options: {
      handler (newVal, oldVal) {
        console.log(newVal)
        if (newVal.page === oldVal.page) {
          if (this.flagSelected === this.$t('Updated Products')) {
            this.getRecords(1)
            this.page = 1
          } else {
            this.togetRecordsSchduled(1)
            this.page = 1
          }
        }
      },
      deep: true
    },
    searchBy: {
      handler (newSearchBy) {
        const updateRecords = {
          searchBy: newSearchBy
        }
        setTempObj('updateRecords', updateRecords)
      },
      deep: true
    },
    store: {
      handler () {
        this.getRecords(1)
        this.getTimezones()
        this.page = 1
      }
    },

    // Scheduled Records update later
    startTime (value) {
      this.scheduleTime = moment(value).format('YYYY-MM-DD HH:mm')
    },
    filesLater (e) {
      console.log(e)
    },
    scheduledTime (val) {
      console.log(val)
    }
    //
  },
  created () {
    this.user = this.$store.state.auth.user
  },
  mounted () {
    this.clearTabindex()
    // button 권한 체크
    // Update Records : Export
    this.$store.dispatch('auth/getDisabledBtn', '3200').then(flag => {
      this.btnShowProductExport = flag
    })
    EventBus.$emit('enableSelectedStores', true)
    const updateRecordsSessionData = getTempObj('updateRecords')
    if (
      !commons.isNull(updateRecordsSessionData) &&
      !commons.isNull(updateRecordsSessionData.searchBy)
    ) {
      this.searchBy = updateRecordsSessionData.searchBy
    }

    if (!this.store.code) {
      EventBus.$emit('openSelectedStores')
      this.allbtndisabled = true
      this.getRecords(1)
    } else {
      this.getRecords(1)
      this.getTimezones()
      this.allbtndisabled = false
    }
    this.flagSelected = this.$t('Updated Products')
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    clearDate (searchBy, dateType) {
      searchBy[dateType] = null
    },
    resetSearchCondition () {
      this.searchBy = {
        fileName: null,
        startDate: null,
        endDate: null
      }
      this.page = 1
      this.getRecords(1)
    },
    saveRequestConfig: function (config) {
      this.requestConfig = getRequestConfig(config)
    },
    buildSearchParams: function (params) {
      if (commons.isValidStr(this.searchBy.fileName)) { params.name = this.searchBy.fileName }
      if (!commons.isNull(this.searchBy.startDate)) { params.start = this.searchBy.startDate }
      if (!commons.isNull(this.searchBy.endDate)) { params.end = this.searchBy.endDate }
      return params
    },
    buildParmas () {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      let params = {
        company: this.user.company,
        store: this.store.code
      }
      params = this.buildSearchParams(params)
      let sort = null
      if (sortBy.length === 1 && sortDesc.length === 1) {
        const sortKey = sortBy[0]
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      if (itemsPerPage > 0) params.size = itemsPerPage
      return params
    },
    getRecords (page) {
      if (this.searchBy.startDate > this.searchBy.endDate) {
        EventBus.$emit('messageAlert', this.$t('The period is invalid.'))
        return
      }
      const params = this.buildParmas()
      if (page !== null) params.page = page - 1
      const config = { params: params }
      this.$utils
        .callAxios(
          codes.requests.getRecords.method,
          codes.requests.getRecords.url,
          config
        )
        .then(res => {
          this.saveRequestConfig(res.config)
          this.tableItems = res.data.articleFileHistoryList.map(list => {
            list.created = commons.convertDate(list.created)
            list.completed = commons.convertDate(list.completed)
            return list
          })
          this.totalRecords = res.data.articleFileHistoryList.length
          this.pageInfoText = this.getPageInfoText(res.headers)
          this.totalPages = Number(res.headers['x-totalpages'])
          this.selected = []
        })
        .catch(error => {
          this.tableItems = []
          this.totalRecords = this.tableItems.length
          const headers = {}
          headers['x-totalelements'] = 0
          this.pageInfoText = this.getPageInfoText(headers)
          this.totalPages = 0
          this.selected = []
          console.debug(`Could not find any update record ${error}`)
        })
    },
    // Scheduled part
    changeType () {
      this.tableItems = []
      if (this.flagSelected === this.$t('Updated Products')) {
        this.options = {
          sortBy: ['created'],
          sortDesc: [true]
        }
        this.getRecords(1)
      } else {
        this.options = {
          sortBy: ['scheduleTime'],
          sortDesc: [true]
        }
        this.togetRecordsSchduled(1)
      }
    },
    scheduledbuildParmas () {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      let params = {
        company: this.user.company,
        store: this.store.code
      }
      params = this.buildSearchParams(params)
      let sort = null
      if (sortBy.length === 1 && sortDesc.length === 1) {
        const sortKey = sortBy[0]
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      if (itemsPerPage > 0) params.size = itemsPerPage
      return params
    },
    togetRecordsSchduled (page) {
      if (this.searchBy.startDate > this.searchBy.endDate) {
        EventBus.$emit('messageAlert', this.$t('The period is invalid.'))
        return
      }
      const params = this.scheduledbuildParmas()
      if (page !== null) params.page = page - 1
      const config = { params: params }
      this.$utils
        .callAxios(
          codes.requests.getScheduledRecords.method,
          codes.requests.getScheduledRecords.url,
          config
        )
        .then(res => {
          this.saveRequestConfig(res.config)
          this.tableItems = res.data.articleScheduleList.map(list => {
            list.scheduleTime = commons.convertDate(list.scheduleTime)
            list.lastModifiedDate = commons.convertDate(list.lastModifiedDate)
            list.processDate = commons.convertDate(list.processDate)
            if (list.executionResult === null) {
              list.executionResult = '-'
            }
            return list
          })
          this.totalRecords = res.data.articleScheduleList.length
          this.pageInfoText = this.getPageInfoText(res.headers)
          this.totalPages = Number(res.headers['x-totalpages'])
          this.selected = []
        })
        .catch(error => {
          this.tableItems = []
          this.totalRecords = this.tableItems.length
          const headers = {}
          headers['x-totalelements'] = 0
          this.pageInfoText = this.getPageInfoText(headers)
          this.totalPages = 0
          this.selected = []
          console.debug(`Could not find any update record ${error}`)
        })
    },
    deleteUpdateRecords () {
      console.log(this.selected)
      const params = {}
      params.id = this.selected[0].id
      const config = { params: params }
      this.$utils
        .callAxios(
          codes.requests.deleteUpdateRecords.method,
          codes.requests.deleteUpdateRecords.url,
          config
        )
        .then(res => {
          console.log(res.data)
          if (res.status === 200) {
            console.log(res.data.responseMessage)
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            this.togetRecordsSchduled(1)
          } else {
            EventBus.$emit(
              'messageAlert',
              this.$t('Something went wrong. Please try after some time.')
            )
          }
        })
        .catch(error => {
          console.log(error)
          EventBus.$emit(
            'messageAlert',
            this.$t('Something went wrong. Please try after some time.')
          )
        })
    },
    handleRowClick (e) {
      console.log(e)
      if (e.fileStatus === 'NOT_PROCESSED') {
        this.rowSelectedData = e
        this.timezone = e.timeZone
        var d = new Date(new Date(e.scheduleTime).toLocaleString('en', { timeZone: e.timeZone }))
        this.startTime = d
        this.filesToUploadLater = [{ name: e.fileName }]
        console.log(e)
        this.editProcessedData = true
      }
    },
    getTimezones () {
      const config = { params: {} }
      return this.$utils
        .callAxios(
          codes.requests.getTimezones.method,
          codes.requests.getTimezones.url,
          config
        )
        .then((res) => {
          this.timezoneList = res.data.timezoneList
          this.timezoneCopy = this.timezoneList
        })
    },
    clearTimeZone () {
      this.$refs.searchTerm.focus()
      this.searchTerm = ''
      this.searchTimezone(this.timezoneList)
    },
    searchTimezone (e) {
      if (!this.searchTerm) {
        this.timezoneList = this.timezoneCopy
      } else {
        this.timezoneList = this.timezoneCopy.filter((fruit) => {
          return (
            fruit.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
          )
        })
      }
    },
    getFieldText (item) {
      return `(${item.timezone}) ${item.name}`
    },
    setCurrentDate (e) {
      if (!this.scheduleTime) {
        console.log(e)
        setTimeout(() => {
          this.scheduleTime = moment(new Date()).format('YYYY-MM-DD HH:00')
        }, 200)
      }
    },
    appendFilesLater (e) {
      console.log(this.filesLater)
      this.filesToUploadLater = []
      this.filesToUploadLater.push(this.filesLater)
    },
    removeFileLater (fileName) {
      this.filesToUploadLater = []
      this.filesLater = []
    },

    // Upload later
    async buildFileUploadBodyUploadLater (files) {
      console.log(files)
      const fileDataList = []
      const fileData = {}
      fileData.imgBase64 = await commons.getBase64(files)
      fileData.pageIndex = this.rowSelectedData.id
      fileData.fileName = files.name
      fileData.size = files.size
      fileDataList.push(fileData)
      return fileDataList
    },

    async handleSaveClick () {
      if (
        this.timezone === null ||
        this.timezone === undefined ||
        this.timezone === ''
      ) {
        EventBus.$emit('messageAlert', this.$t('Select Time Zone.'))
        return
      }
      if (
        this.scheduleTime === null ||
        this.scheduleTime === undefined ||
        this.scheduleTime === ''
      ) {
        EventBus.$emit('messageAlert', this.$t('Select Schedule Time.'))
        return
      }
      if (
        this.filesToUploadLater.length === 0
      ) {
        EventBus.$emit('messageAlert', this.$t('Select files to upload.'))
        return
      }
      var reqBody = ''
      var config = {}
      var body = {}
      var flagcheck = this.rowSelectedData.fileName === this.filesToUploadLater[0].name
      if (!flagcheck) {
        reqBody = await this.buildFileUploadBodyUploadLater(
          this.filesLater
        )
        var sizeInMB = (reqBody[0].size / (1024 * 1024)).toFixed(2)
        if (sizeInMB > 14) {
          EventBus.$emit(
            'messageAlert',
            this.$t('Files larger than 14MB cannot be uploaded.')
          )
          return
        }
        const params = { store: this.store.code }
        config = { params: params }
        body = {
          contentType: reqBody[0].contentType,
          binaryData: reqBody[0].imgBase64,
          id: reqBody[0].pageIndex,
          fileName: reqBody[0].fileName,
          scheduleTime: this.scheduleTime,
          timezone: this.timezone
        }
      } else {
        const params = { store: this.store.code }
        config = { params: params }
        body = {
          binaryData: '',
          id: this.rowSelectedData.id,
          fileName: this.rowSelectedData.fileName,
          scheduleTime: this.scheduleTime,
          timezone: this.timezone
        }
      }

      console.log(body)
      this.$utils
        .callAxiosWithBody(
          this.requests.uploadFileslater.method,
          this.requests.uploadFileslater.url,
          body,
          config
        )
        .then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.editProcessedData = false
          this.togetRecordsSchduled(1)
        })
        .catch((error) => {
          switch (error.response.status) {
            default:
              EventBus.$emit(
                'messageAlert',
                this.$t(
                  'The file format is not matched with the field configuration. Please check the configuration in Product File Config.'
                )
              )
              break
          }
          this.editProcessedData = false
        })
    },
    // end Schedule

    exportRecords () {
      exportFiles(
        this.requestConfig,
        this.$refs.link,
        'ProductHistoryList.xlsx'
      )
    },
    getPageInfoText: function (headers) {
      if (headers['x-totalelements'] === 0) return '0 to 0, 0' + ' ' + this.$t('in total')
      return `${headers['x-number'] * 1 * headers['x-size'] + 1} 
      ${this.$t('to')} ${(headers['x-number'] * 1 * headers['x-size']) + headers['x-total-count'] * 1},
       ${headers['x-totalelements'] * 1}` + ' ' + this.$t('in total')
    },
    paging () {
      this.getRecords(this.page)
    },
    searchRecords () {
      if (this.flagSelected === this.$t('Updated Products')) {
        this.getRecords(1)
      } else {
        this.togetRecordsSchduled(1)
      }
    },
    checkStartDate (val) {
      if (val > this.searchBy.endDate) {
        this.searchBy.startDate = null
        EventBus.$emit(
          'messageAlert',
          this.$t('The start date is must be prior to the end date.')
        )
      }
    },
    checkEndDate (val) {
      if (val < this.searchBy.startDate) {
        this.searchBy.endDate = null
        EventBus.$emit(
          'messageAlert',
          this.$t('The end date is must be after the start date.')
        )
      }
    },

    toDownloadarticles (data) {
      console.log(data)
      if (this.flagSelected === this.$t('Updated Products')) {
        const config = { params: { id: data.id } }
        this.$utils
          .callAxios(
            codes.requests.downloadRecords.method,
            codes.requests.downloadRecords.url,
            config
          )
          .then(res => {
            console.log(res)
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', data.name)
            document.body.appendChild(link)
            link.click()
          })
      } else {
        const config = { params: { id: data.id } }
        this.$utils
          .callAxios(
            codes.requests.downloadscheduledRecords.method,
            codes.requests.downloadscheduledRecords.url,
            config
          )
          .then(res => {
            console.log(res)
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', data.fileName)
            document.body.appendChild(link)
            link.click()
          })
      }
    }
  }
}
</script>
<style>
.downloadIcon{
  height: 15px;
    vertical-align: middle;
    position: relative;
    left: 21px;cursor:pointer
}
.redbold {
  color: red;
  font-weight: bold;
}
</style>
